import { getCookie, setCookie } from '../shared/utilities/cookies';

function getIsRedirectRestirctedForEveryRuleIncluded(searchParam, paramANDArr) {
	let isRedirectRestricted = true;
	const searchString = new URLSearchParams(searchParam);

	/* istanbul ignore else */
	if (paramANDArr) {
		// eslint-disable-next-line
		for (const key of paramANDArr) {
			/* istanbul ignore else */
			if (key.indexOf('=') > -1) {
				const paramPair = key.split('=');
				if (!(searchString.has(paramPair[0]) && searchString.get(paramPair[0]) === paramPair[1])) {
					isRedirectRestricted = false;
					break;
				}
			} else if (!searchString.has(key)) {
				isRedirectRestricted = false;
				break;
			}
		}
	}
	return isRedirectRestricted;
}

function getIsRedirectRestrictedForAtLeastOneRule(searchParam, paramORArr) {
	let isRedirectRestricted = false;
	const searchString = new URLSearchParams(searchParam);

	/* istanbul ignore else */
	if (paramORArr) {
		// eslint-disable-next-line
		for (const key of paramORArr) {
			/* istanbul ignore else */
			if (key.indexOf('=') > -1) {
				const paramPair = key.split('=');
				if (searchString.has(paramPair[0]) && searchString.get(paramPair[0]) === paramPair[1]) {
					isRedirectRestricted = true;
					break;
				}
			} else if (searchString.has(key)) {
				isRedirectRestricted = true;
				break;
			}
		}
	}
	return isRedirectRestricted;
}

function getIsRedirectRestricted(searchParam, winParamString) {
	// eslint-disable-next-line
	const paramORArr = winParamString?.split('|');
	const paramANDArr = winParamString?.split('&');

	if (paramANDArr && paramANDArr.length > 1) {
		return getIsRedirectRestirctedForEveryRuleIncluded(searchParam, paramANDArr);
	}
	return getIsRedirectRestrictedForAtLeastOneRule(searchParam, paramORArr);
}

function geoRedirect(cultures) {
	const countryCode = getCookie('iit_ak_geo');
	const returningCookie = getCookie('iit_returning') || '0';

	if (countryCode && cultures[countryCode] && returningCookie !== '1') {
		setCookie('iit_returning', '1');
		const queryParam = window.location.search.substring(1);
		const fragmentQuery = window.location.hash.substring(1);

		/* istanbul ignore else */
		if (
			!(
				(queryParam && getIsRedirectRestricted(queryParam, window.queryParamString)) ||
				(fragmentQuery && getIsRedirectRestricted(fragmentQuery, window.fragmentString))
			)
		) {
			window.location.assign(
				cultures[countryCode].replace(/\/$/, '') + window.location.search + window.location.hash
			);
		}
	}
}

export function isGeoRedirectAvailable() {
	const countryCode = getCookie('iit_ak_geo');
	return Boolean(countryCode && window.cultures?.[countryCode]);
}

if (window.cultures) {
	geoRedirect(window.cultures);
}
/* istanbul ignore else */
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
	module.exports = {
		geoRedirect,
		getIsRedirectRestricted,
		isGeoRedirectAvailable
	};
}
